<template>
  <Paginator :config="config" class="v-paginator">
    <template v-slot="{ paginator, toPostComponent, hideFilter, noEntriesFoundText, loadMore }">
      <LightboxFilter v-if="!hideFilter && filterViewMode === 'lightbox'" :paginator="paginator" />

      <div class="v-paginator-posts-events">
        <slot name="posts" v-bind="{ paginator, toPostComponent }">
          <div v-for="(posts, date) in groupedPosts(paginator).value" :key="date" class="v-paginator-posts-group">
            <div class="v-paginator-posts-group-title">{{ DateTime.fromSeconds(parseInt(`${date}`)).monthShort }}</div>
            <div class="v-paginator-posts-group-events">
              <component v-for="post in posts" :key="post.id" :is="toPostComponent(post.id)"></component>
            </div>
          </div>
        </slot>
      </div>

      <div v-if="noEntriesFoundText && !paginator.posts.value.length && !paginator.loading.value">{{ noEntriesFoundText }}</div>
      <div v-if="loadMore && paginator.hasNextPage.value" class="v-paginator-load-more">
        <div class="btn btn-link" @click="paginator.loadMore()">
          <InlineSpinner v-if="paginator.loading.value" />
          <div>mehr</div>
        </div>
      </div>
    </template>
  </Paginator>
</template>

<script setup lang="ts">
import { InlineSpinner, Paginator, PaginatorType } from 'wly-theme-extensions';
import LightboxFilter from '@/js/vue/components/Paginator/LightboxFilter.vue';
import { computed, toRaw } from 'vue';
import { DateTime } from 'luxon';

defineProps({
  config: {
    type: Object,
    required: true,
  },
  filterViewMode: {
    type: String,
    default: () => 'lightbox',
  },
});

const groupedPosts = (paginator: PaginatorType) =>
  computed(() => {
    const posts = Object.groupBy(paginator.posts.value, ({ post }: any) => {
      const start = DateTime.fromSeconds(post.event_start);

      return DateTime.fromObject({
        year: start.year,
        month: start.month,
        day: 1,
      }).toSeconds();
    });

    return toRaw(posts);
  });
</script>
