<template>
  <div class="form-check" :class="{ disabled }">
    <label class="form-check-label font--medium">
      <input class="form-check-input" type="checkbox" :id="name" :name="name" v-model="model" :value="value" @change="onChanged($event)" />
      {{ label }}
    </label>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  modelValue: { type: [Array, Boolean] },
  value: { type: [Boolean, Object], default: () => true },
  label: { type: String, required: true },
  name: { type: String, required: true },
  disabled: { type: Boolean, default: () => false },
});

const emit = defineEmits(['update:modelValue']);
const emitValue = (value) => emit('update:modelValue', value);

const model = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emitValue(value);
  },
});

const onChanged = ($event) => {
  // make sure to sync the checked status of the checkbox. it could happen that the checked status has changed
  // without actually emitting the same value
  $event.target.checked = model.value;
};
</script>
