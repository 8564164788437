<template>
  <div class="v-lightbox" :class="[`position-${position}`]">
    <div class="v-lightbox-toggle" @click="handleToggleClick">
      <slot name="toggle"> Öffne Mich </slot>
    </div>

    <Transition name="fade">
      <div v-if="open && position !== 'fixed'" class="v-lightbox-content-background" @click="handleClose"></div>
    </Transition>

    <template v-if="position === 'fixed'">
      <Transition name="fade-slide-up">
        <div v-if="open" class="v-lightbox-content-container" @click="handleClose">
          <div class="v-lightbox-content" @click.stop="() => {}">
            <Close v-if="showCloseButton" @click="handleClose" />
            <slot name="content" />
          </div>
        </div>
      </Transition>
    </template>
    <template v-else>
      <Transition name="fade-slide-up">
        <div v-if="open" class="v-lightbox-content">
          <Close v-if="showCloseButton" @click="handleClose" />
          <slot name="content" />
        </div>
      </Transition>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue';
import Close from '@/js/vue/components/Icons/Close.vue';

const props = defineProps({
  showCloseButton: {
    type: Boolean,
    default: () => false,
  },
  position: {
    type: String,
    default: () => 'absolute',
  },
});

const open = ref(false);

const handleEscPress = (e: KeyboardEvent) => {
  if (open.value && e.key === 'Escape') {
    e.preventDefault();
    e.stopImmediatePropagation();

    handleClose();
  }
};

const handleToggleClick = () => {
  open.value = !open.value;
};

const handleClose = () => {
  open.value = false;
};

watch(open, (open) => {
  if (open && props.position === 'fixed') {
    document.documentElement.style.overflow = 'hidden';
  } else {
    document.documentElement.style.overflow = 'auto';
  }
});

onMounted(() => {
  window.addEventListener('keydown', handleEscPress);

  return () => window.removeEventListener('keydown', handleEscPress);
});
</script>

<style lang="scss" scoped>
.v-lightbox {
  position: relative;

  &.position-fixed {
    .v-lightbox-content-background {
      z-index: 100;
    }

    .v-lightbox-content-container {
      // We need an offset, because the dark background is part of the animated container,
      // Otherwise the would be a transparent space while the container is animated to the correct position.
      $background-animation-offset: 50px;

      position: fixed;
      top: $background-animation-offset * -1;
      left: $background-animation-offset * -1;
      right: $background-animation-offset * -1;
      bottom: $background-animation-offset * -1;
      z-index: 100;
      overflow-y: auto;
      background: rgb(0 0 0 / 62%);
      backdrop-filter: blur(10px);
      padding-top: responsive-value(132px + $background-animation-offset, 40px + $background-animation-offset);
      padding-bottom: responsive-value(132px + $background-animation-offset, 40px + $background-animation-offset);
      padding-left: responsive-value(237px + $background-animation-offset, 40px + $background-animation-offset);
      padding-right: responsive-value(237px + $background-animation-offset, 40px + $background-animation-offset);
    }

    .v-lightbox-content {
      position: relative;
    }
  }

  .v-close-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    border-radius: 100%;
    width: 34px;
    height: 34px;
    background: $secondary-dark;
    color: $white;
    transition: background $transition-duration $transition-curve;

    &:hover {
      background: $secondary;
    }
  }
}

.v-lightbox-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  transform: translateY(0px);
  border-radius: 25px;
  background: $white;
  box-shadow: -5px 5px 25px 0px rgba(0, 0, 0, 0.05);
  padding: 55px 75px 65px;
  opacity: 1;
}

.v-lightbox-toggle {
  cursor: pointer;
}

.v-lightbox-content-background {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  opacity: 1;
  z-index: 1;
  background: rgb(0 0 0 / 62%);
  backdrop-filter: blur(10px);
}

.fade-enter-active,
.fade-leave-active,
.fade-slide-up-enter-active,
.fade-slide-up-leave-active {
  transition: all $transition-duration $transition-curve;
}

.fade-enter-from,
.fade-leave-to,
.fade-slide-up-enter-from,
.fade-slide-up-leave-to {
  backdrop-filter: blur(0px);
  opacity: 0;
}

.fade-slide-up-enter-from,
.fade-slide-up-leave-to {
  transform: translateY(20px);
}
</style>
