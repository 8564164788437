import $ from 'jquery';

const handleMouseMove = (event: MouseEvent, el: HTMLElement) => {
  // Das Elternelement des aktuellen Elements
  const parent = $(el).parent();

  // Berechne die Position relativ zum Elternelement
  const parentOffset = parent.offset();
  const parentWidth = parent.width();
  const parentHeight = parent.height();

  // Berechne den Maus-Offset relativ zum Elternelement
  const mouseX = event.pageX - parentOffset.left;
  const mouseY = event.pageY - parentOffset.top;

  // Berechne den Parallaxen-Effekt, skaliert für das Elternelement
  const x = (mouseX - parentWidth / 2) / 30; // Skalierung mit Bezug auf die Mitte
  const y = (mouseY - parentHeight / 2) / 30; // Skalierung mit Bezug auf die Mitte

  // Setze den transform-Stil
  $(el).css({
    transform: `translateX(${x}px) translateY(${y}px)`,
  });
};

export const animateBorder = (el: HTMLElement) => {
  const listener = (e: MouseEvent) => handleMouseMove(e, el);
  const activator: HTMLElement = el.closest('.hover--border--activator');

  activator.addEventListener('mousemove', listener);

  return () => activator.removeEventListener('mousemove', listener);
};
