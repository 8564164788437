// Import polyfills
import './js/polyfills';
import './js/libraries';
import './js/scripts';

import { RunThemeExtensions } from 'wly-theme-extensions';

import Paginator from '@/js/vue/components/Paginator.vue';
import YoutubePlayer from '@/js/vue/components/YoutubePlayer.vue';

RunThemeExtensions({
  vue: {
    components: {
      'wly-paginator': Paginator,
      'wly-event-paginator': EventPaginator,
      'wly-youtube': YoutubePlayer,
      'wly-lightbox': Lightbox,
      'wly-animated-border': AnimatedBorder,
    },
    onBootstrap: (app) => {
      Settings.defaultLocale = app.i18n?.locale?.toLowerCase().replace('_', '-') || 'de-ch';
    },
    i18n: {
      //
    },
  },
  alpine: {
    components: {
      //
    },
  },
});

// Import styles
// Should be last to override vue component styles more easily
import './sass/style.scss';
import EventPaginator from '@/js/vue/components/EventPaginator.vue';
import { DateTime, Settings } from 'luxon';
import Lightbox from "@/js/vue/components/Lightbox.vue";
import AnimatedBorder from "@/js/vue/components/AnimatedBorder.vue";
