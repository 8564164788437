<template>
  <PostsFilter :paginator="paginator">
    <template v-slot:default="{ taxonomies, toggle, selectedTerms, loading, paginator }">
      <Lightbox class="v-paginator-lightbox-filter" show-close-button>
        <template #toggle>
          <div class="v-tabs">
            <div class="v-tab-taxonomy" v-for="taxonomy in taxonomies" :key="taxonomy.slug" @click="selectedTaxonomy = taxonomy.slug">
              <div class="v-tab-content">
                <div class="v-tab-title">
                  {{ taxonomy.filter_title }}
                </div>
                <div class="v-tab-subtitle font--mini">
                  {{
                    $rt(taxonomy.filter_subtitle_placeholder, paginator.taxonomyFilterItems.value[taxonomy.slug]?.length ?? 0, {
                      count: paginator.taxonomyFilterItems.value[taxonomy.slug]?.length ?? 0,
                    })
                  }}
                </div>
              </div>
            </div>

            <div class="v-tab-actions">
              <InlineSpinner v-if="paginator.loading.value" />
              <div class="cursor-pointer" v-if="paginator.hasActiveFilter.value" @click.prevent.stop="paginator.resetFilter()">
                <Undo />
              </div>
            </div>
          </div>
        </template>

        <template #content>
          <div class="v-paginator-lightbox-filter-lightbox-content">
            <div class="v-tabs">
              <div
                v-for="(taxonomy, index) in taxonomies"
                :key="taxonomy.slug"
                class="v-tab-taxonomy"
                :class="{ active: taxonomy.slug === selectedTaxonomy || (!selectedTaxonomy && !index) }"
                @click="selectedTaxonomy = taxonomy.slug"
              >
                <div class="v-tab-content">
                  <div class="v-tab-title">
                    {{ taxonomy.filter_title }}
                  </div>
                  <div class="v-tab-subtitle font--mini">
                    {{
                      $rt(taxonomy.filter_subtitle_placeholder, paginator.taxonomyFilterItems.value[taxonomy.slug]?.length ?? 0, {
                        count: paginator.taxonomyFilterItems.value[taxonomy.slug]?.length ?? 0,
                      })
                    }}
                  </div>
                </div>
              </div>

              <div class="v-tab-actions font--mini v-tab-actions--small">
                <InlineSpinner v-if="paginator.loading.value" />
                <div class="v-tab-actions__wrapper" v-if="paginator.hasActiveFilter.value" @click="paginator.resetFilter()">{{ $t('paginator.reset') }} <Undo /></div>
              </div>
            </div>

            <div class="v-tab-terms">
              <template v-for="term in (taxonomies.find((t) => t.slug === selectedTaxonomy) ?? taxonomies.at(0))?.options ?? []" :key="term.id">
                <Checkbox
                  :label="term.label"
                  :name="`checkbox-${term.id}`"
                  :model-value="paginator.isActiveTaxonomyFilter(selectedTaxonomy, term.id).value"
                  @update:modelValue="() => paginator.setTaxonomyFilter(selectedTaxonomy, term.id)"
                  :disabled="term.disabled"
                />
              </template>
            </div>
          </div>
        </template>
      </Lightbox>
    </template>
  </PostsFilter>
</template>

<script lang="ts" setup>
import { InlineSpinner, PaginatorType, PostsFilter } from 'wly-theme-extensions';
import { PropType, ref } from 'vue';
import Checkbox from '@/js/vue/components/Checkbox.vue';
import Lightbox from '@/js/vue/components/Lightbox.vue';
import Undo from '@/js/vue/svg/Undo.vue';

defineProps({
  paginator: {
    type: Object as PropType<PaginatorType>,
  },
});

const selectedTaxonomy = ref<string>(null);
</script>
