<template>
  <div ref="el" class="hover--border"></div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { animateBorder } from '@/js/scripts/animated-border';

const el = ref<HTMLElement>();

onMounted(() => {
  return animateBorder(el.value);
});
</script>
